<template>
  <div>
    <h1
      class="
        border-0
        font-semibold
        border-b border-solid border-gray-400
        py-3
        px-2
        text-blackdok text-xl
      "
    >
      Mes abonnements
    </h1>
    <div class="px-2 mt-8">
      <!-------------------------------------- inscription -------------------------------->
      <div
        class="flex flex-col md:grid md:grid-cols-4 mt-8 p-5"
        v-if="user.payment && user.payment.done"
      >
        <label class="xl:mr-5 w-1/3 font-EffraR">Votre inscription</label>
        <div class="flex flex-col col-span-2 md:mt-0 mt-3 font-EffraL">
          <div
            class="pt-2"
            v-if="moment()(user.payment.nextDate).isAfter(moment()())"
          >
            <span class="mr-5"
              >Prochaine date de paiement :
              <strong class="capitalize">{{
                moment()(user.payment.nextDate).format("dddd DD MMMM YYYY")
              }}</strong></span
            >

            <dok-button size="sm" bg="red" @click.native="cancelSubscription"
              >Résilier l'abonnement</dok-button
            >
          </div>
          <div v-else>
            <strong
              >Vous vous n'êtes toujours pas abonné à dokPro premium</strong
            >
          </div>
        </div>
      </div>

      <!-------------------------------------- offres -------------------------------->
      <div
        class="p-5"
        v-if="
          !user.payment ||
          !user.payment.done ||
          !user.payment.typeSubscription ||
          user.payment.typeSubscription == 'FREE'
        "
      >
        <label class="xl:mr-5 w-1/3 font-EffraR">Les offres d'abonnement</label>
        <div class="w-1/2 mx-auto">
          <div class="flex-1 grid gap-x-0 grid-cols-1 lg:grid-cols-3">
            <div
              v-for="(item, index) in subscriptionProducts"
              :key="'btnSp-' + index"
              class="
                flex flex-col
                items-center
                justify-center
                border-0 border-b-2 border-solid
                cursor-pointer
                py-6
              "
              :class="
                (item.metaData['_subscription_period_interval'] > 1
                  ? item.metaData['_subscription_period_interval']
                  : '') +
                  item.metaData['_subscription_period'] ==
                packSubcription
                  ? 'bg-dokBlue-lighter border-dokBlue-ultra'
                  : 'bg-transparent border-gray-400'
              "
              @click="
                setPack(
                  (item.metaData['_subscription_period_interval'] > 1
                    ? item.metaData['_subscription_period_interval']
                    : '') + item.metaData['_subscription_period']
                )
              "
            >
              <span id="title" class="font-EffraR text-base text-black">{{
                item.name
              }}</span>
              <span id="price" class="font-EffraM text-base text-gray"
                >{{
                  $formatNumber(item.metaData["_subscription_price"])
                }}
                MAD</span
              >
              <span id="price" class="font-EffraM text-base text-grayaaa">
                ~
                {{
                  Math.ceil(
                    item.metaData["_subscription_price"] /
                      (item.metaData["_subscription_period"] == "year"
                        ? 12
                        : item.metaData["_subscription_period_interval"])
                  )
                }}
                MAD / mois</span
              >
            </div>
          </div>
        </div>

        <!-- CHOISIR & PLUS DE DETAILS -->
        <div class="grid grid-cols-4 mt-10">
          <div></div>
          <div class="w-full mx-auto px-10">
            <dok-button
              size="md"
              bg="blue"
              custom-class="w-full"
              @click.native="payInscription()"
            >
              Choisir
            </dok-button>
          </div>

          <!-- More Info -->
          <div class="w-full mx-auto px-10">
            <dok-button
              size="md"
              bg="blue"
              custom-class="w-full"
              @click.native="onClickMoreDetails"
            >
              Plus de détails
            </dok-button>
          </div>
          <div></div>
          <!-- END ACTIONS -->
        </div>

        <div
          v-if="0 && !loadSubscriptionProduct"
          class="hidden gap-x-2 justify-center"
        >
          <dok-button
            size="lg"
            :bg="
              item.metaData['_subscription_period'] == 'month'
                ? 'blue'
                : 'green'
            "
            v-for="(item, index) in subscriptionProducts"
            :key="'btnSp-' + index"
            @click.native="
              payInscription(
                (item.metaData['_subscription_period_interval'] > 1
                  ? item.metaData['_subscription_period_interval']
                  : '') + item.metaData['_subscription_period']
              )
            "
          >
            {{ item.name }}
          </dok-button>
        </div>
        <div v-if="loadSubscriptionProduct" class="flex justify-center">
          <div class="fa-3x">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
        </div>
      </div>

      <!-------------------------------------- Historique -------------------------------->
      <div class="p-5">
        <label class="xl:mr-5 w-1/3 font-EffraR"
          >Historique d'abonnements</label
        >
        <div v-if="!loadSubscriptionHistory" class="mt-5">
          <table
            class="w-full"
            style="border-spacing: 0; border-collapse: collapse"
          >
            <thead>
              <tr>
                <th
                  class="
                    hidden
                    xl:table-cell
                    border border-l-0 border-t-0 border-solid border-gray-300
                    bg-dokBlue-ultra
                    text-white text-lg
                    font-EffraR font-normal
                    px-2
                    py-3
                    rounded-tl-lg
                  "
                >
                  Date début
                </th>
                <th
                  class="
                    hidden
                    xl:table-cell
                    border border-solid border-t-0 border-gray-300
                    bg-dokBlue-ultra
                    text-white text-lg
                    font-EffraR font-normal
                    px-2
                    py-3
                  "
                >
                  Date fin
                </th>
                <th
                  class="
                    hidden
                    xl:table-cell
                    border border-t-0 border-solid border-gray-300
                    bg-dokBlue-ultra
                    text-white text-lg
                    font-EffraR font-normal
                    px-2
                    py-3
                  "
                >
                  Montant
                </th>
                <th
                  class="
                    hidden
                    xl:table-cell
                    border border-t-0 border-r-0 border-solid border-gray-300
                    bg-dokBlue-ultra
                    text-white text-lg
                    font-EffraR font-normal
                    px-2
                    py-3
                    rounded-tr-lg
                  "
                >
                  Carte utilisée
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="
                  flex
                  xl:table-row
                  flex-row
                  xl:flex-row
                  flex-wrap
                  xl:flex-no-wrap
                "
                v-for="(item, idex) in subscriptionHistory"
                :key="'history-' + idex"
              >
                <td
                  class="
                    w-full
                    xl:w-auto
                    border border-solid border-gray-300
                    text-lg
                    font-EffraR font-normal
                    py-3
                    px-2
                  "
                >
                  {{ moment()(item.payment_date).format("DD MMM YYYY") }}
                </td>
                <td
                  class="
                    w-full
                    xl:w-auto
                    border border-solid border-gray-300
                    text-lg
                    font-EffraR font-normal
                    py-3
                    px-2
                  "
                >
                  {{ moment()(item.next_payment_date).format("DD MMM YYYY") }}
                </td>
                <td
                  class="
                    w-full
                    xl:w-auto
                    border border-solid border-gray-300
                    text-lg
                    font-EffraR font-normal
                    py-3
                    px-2
                  "
                >
                  {{ item.amount === 0 ? "GRATUIT" : item.amount }}
                </td>
                <td
                  class="
                    w-full
                    xl:w-auto
                    border border-solid border-gray-300
                    text-lg
                    font-EffraR font-normal
                    py-3
                    px-2
                  "
                >
                  {{
                    item.payment_mean_info
                      ? JSON.parse(item.payment_mean_info).cardBrand +
                        " - " +
                        JSON.parse(item.payment_mean_info).cardNumber
                      : "-"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="loadSubscriptionHistory" class="flex justify-center">
          <div class="fa-3x">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from "moment";
  export default {
    data() {
      return {
        packSubcription: null,
        user: {},
        subscriptionProducts: [],
        subscriptionHistory: [],
        loadSubscriptionHistory: true,
        loadSubscriptionProduct: true,
        translateMap: {
          month: "Mois",
          year: "Année",
        },
      };
    },
    async mounted() {
      await this.refreshMe();
      let userCur = await this.$auth.getUserInfo();
      this.user = userCur;
      this.getSubscriptionProducts();
      this.getSubscriptionHistory();
      this.setPack("year");
    },
    methods: {
      setPack(pack) {
        this.packSubcription = pack;
      },
      getSubscriptionHistory() {
        this.$store.dispatch("payments/GET_SUBSCRIPTION_HISTORY", {
          onData: (data) => {
            this.subscriptionHistory = data.subscriptions;
            this.loadSubscriptionHistory = false;
          },
        });
      },
      getSubscriptionProducts() {
        this.$store.dispatch("payments/GET_SUBSCRIPTION_PRODUCTS", {
          onData: (data) => {
            this.subscriptionProducts = data;
            this.loadSubscriptionProduct = false;
          },
        });
      },
      payInscriptionMonthly() {
        // alert('Month');
        this.payInscription("month");
      },
      payInscriptionYearly() {
        this.payInscription("year");
      },
      payInscription: function () {
        let period = this.packSubcription;
        this.isPaying = true;
        if (!this.user.payment.customerId) {
          //TODO create customer for this user
          this.$store.dispatch("payments/CREATE_CUSTOMER_FOR_SUBSCRIPTION", {
            params: {
              type: period,
            },
            onData: this.onCustomData,
          });
        } else {
          this.onCustomData({
            period: period,
            data: this.user,
            ok: true,
            message: "",
          });
        }
      },
      onCustomData({ period, data, ok, message }) {
        if (
          ok &&
          data.payment.customerId &&
          data.payment.customerId != "undefined"
        ) {
          let subscriptionUserId = data.payment.customerId;

          window.open(
            process.env.VUE_APP_PAYMENT_SRV_URL +
              `/payment/subscription/${
                data._id
              }/${subscriptionUserId}?period=${period}&token=${this.$auth.getToken()}`,
            "_blank"
          );
        } else {
          this.errorMsg = message;
          // alert message
        }
      },
      async cancelSubscription() {
        alert("Canceling subscription");
      },
      refreshMe() {
        this.$store.dispatch("user/GET_PROFILE", {
          onData: this.refreshData,
        });
      },
      refreshData(data) {
        this.isPaying = false;
        this.user = this.$auth.getUserInfo();
      },

      moment() {
        return moment;
      },
      onClickMoreDetails() {
        this.$router.push({ name: "pricingPage" });
      },
    },
  };
</script>
<style lang="scss">
.title-h1 {
  font-size: 20px;
  font-family: "Effra";
}
</style>
